import bg from './Holdonnbg.png';
import './App.css';

function App() {
  return (
    <div className="App" style={{display:"flex", justifyContent:"center", alignItems:"center",height:"100vh"}}>
      <img src={bg} alt="" style={{width:"100%", objectFit:"cover"}}/>
    </div>
  );
}

export default App;
